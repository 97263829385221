import React from 'react'
import tw from 'twin.macro'

const ModalBackdrop = props =>
  props.show ? (
    <div
      css={[
        'width: 100%;height: 100%;position: fixed;z-index: 100;',
        'left: 0;top: 0;background-color: rgba(0, 0, 0, 0.5);',
      ]}
      onClick={props.clicked}
    ></div>
  ) : null

const Modal = props => (
  <>
    <ModalBackdrop show={props.show} clicked={props.modalClosed} />
    <div
      css={[
        'position: fixed;z-index: 500;background-color: white;',
        'transform: translate(-50%, -50%);padding: 16px;max-height: 80%;width: 80%;',
        'left: 50%;top: 50%;box-sizing: border-box;transition: all 0.3s ease-out;overflow-y: auto;',
        tw`rounded-lg shadow-lg box-border border border-gray-300`,
      ]}
      style={{
        transition: props.show ? 'opacity 0.5s' : '',
        opacity: props.show ? '1' : '0',
        visibility: props.show ? 'visible' : 'hidden',
      }}
    >
      {props.children}
    </div>
  </>
)

export default Modal
