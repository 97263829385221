import React from 'react'
import Modal from '../Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import tw from 'twin.macro'

import { H2 } from '../Headings'
import PestCard from './PestCard'

const PestSelectModal = ({ show, onClose, pestType }) => {
  return (
    <>
      <Modal show={show} modalClosed={onClose}>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          onClick={onClose}
          tw={'float-right text-yellow-500 cursor-pointer'}
        />
        <H2 tw="text-center text-gray-800 mt-8">{pestType.title}</H2>
        <div tw="text-center text-gray-700 mx-auto max-w-xl" dangerouslySetInnerHTML={{ __html: pestType.description }} />
        <div tw="container mx-auto mb-6 px-4 md:px-12">
          <div tw="flex flex-wrap justify-center mx-auto mt-2">
            {pestType &&
              pestType.pests &&
              pestType.pests.length > 0 &&
              pestType.pests.map(pest => {
                return <PestCard key={pest.id} title={pest.title} slug={pest.slug} imagePath={pest.fixedImagePath} />
              })}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PestSelectModal
