import React from 'react'
import tw from 'twin.macro'
import Img from 'gatsby-image'
import {navigate} from 'gatsby'

const PestCard = ({ title, slug, imagePath, pests, onClick, horizontal }) => {
    return (
      <article
        onClick={() => onClick ? onClick(slug) : navigate(`/learn/${slug}`)}
        css={[
            tw`p-4 border box-border border-transparent overflow-hidden rounded-lg z-50 hover:(cursor-pointer shadow-lg border-gray-300) transition ease-in-out duration-150`,
            horizontal && tw`flex flex-col items-center sm:(flex-row)`
        ]}
      >
          <div tw="text-center">
            {typeof imagePath === 'object' ?
                <Img fixed={imagePath} />
            :
                <img
                    src={imagePath}
                    alt={title}
                    css={['height: 80px;', tw`inline`]}
                />
            }
          </div>
          <div
            css={[
              'max-width:220px; font-size: 18px;',
              tw`text-center mx-auto font-sans font-bold text-gray-800`,
            ]}
          >
            {title}
          </div>
          {pests ? <div
            css={[
              'opacity: 0.8; max-width:200px; font-size: 14px; display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;',
              tw`text-center mt-1 mb-2 mx-auto font-sans`,
            ]}
          >
            {pests.map(x => x.title).join(', ')}
          </div> : null}
      </article>
    )
  }

  export default PestCard